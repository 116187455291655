import React from "react";
import PropTypes from "prop-types";
import ToolTip from "../ToolTip/ToolTip";


// TODO : consider refactoring to a bootstrap dropdown. Make sure to test for ADA first.

const Select = (props) => {
  const {
    children,
    id,
    value,
    handler,
    optionNames = [],
    optionValues = [],
    label,
    description,
    disabled,
    tooltip,
    ariaControls,
    hasSupportingInfoBelow,
    isLarge,
    ...rest
  } = props;

  const uniqueString = () => Math.random().toString().slice(2);
  const uniqueId = id || uniqueString();

  return (
    <div
      className="Select form-group"
      style={{
        marginBottom: hasSupportingInfoBelow ? "4px" : "1rem",
      }}
    >
      <label htmlFor={uniqueId} className={isLarge ? "col-form-label-lg" : ""}>
        {label}
        {tooltip && <ToolTip id={uniqueId + "_tooltip"} message={tooltip} />}
      </label>
      <div className="input-group">
        <select
          id={uniqueId}
          value={value}
          disabled={disabled}
          className={["form-control", isLarge ? "form-control-lg" : ""].join(
            " "
          )}
          aria-describedby={`description-${uniqueId}`}
          aria-controls={ariaControls}
          onChange={(e) => handler(e)}
          {...rest}
        >
          {children ||
            optionNames.map((option, i) => {
              return (
                <option key={i} value={optionValues ? optionValues[i] : option}>
                  {option}
                </option>
              );
            })}
        </select>
      </div>
      <small id={`description-${uniqueId}`} className="form-text text-muted">
        {description}
      </small>
    </div>
  );
};

export default Select;

Select.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func,
  optionNames: PropTypes.array,
  optionValues: PropTypes.array,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  isLarge: PropTypes.bool,
  ariaControls: PropTypes.string,
  hasSupportingInfoBelow: PropTypes.bool,
  label: PropTypes.string,
};
