import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Select from "../../shared/InputElements/Select";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ChargingAvailabilityWizard from "../../ChargingAvailabilityWizard/ChargingAvailabilityWizard";
import { FormattedMessage, useIntl} from 'react-intl';

const SelectChargerLevel = ({
  id = "select-charger-level",
  ...rest
}) => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false);
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "evs.homeChargingAvailability", defaultMessage: "Home Charging Availability"}) : "Home Charging Availability"

  return (
    <>
      <Select
        id={id}
        value={userPrefs.get("chargerLevel")}
        label={label}
        optionNames={[
          intl.formatMessage
            ? intl.formatMessage({
                id: "evs.noCharging",
                defaultMessage: "No Charging",
              })
            : "No Charging",
          intl.formatMessage
            ? intl.formatMessage({
                id: "evs.levelOne",
                defaultMessage: "Level 1",
              })
            : "Level 1",
          intl.formatMessage
            ? intl.formatMessage({
                id: "evs.levelTwo",
                defaultMessage: "Level 2",
              })
            : "Level 2",
        ]}
        optionValues={["no_charger", "level_1", "level_2"]}
        ariaControls="electric-vehicles-catalog"
        tooltip={
          intl.formatMessage
            ? intl.formatMessage({
                id: "evs.homeChargingAvailabilityTooltip",
                defaultMessage:
                  "Driving and charging an EV is most convenient when you charge where you live",
              })
            : "Driving and charging an EV is most convenient when you charge where you live"
        }
        hasSupportingInfoBelow
        handler={(e) => userPrefs.set({ chargerLevel: e.target.value })}
        {...rest}
      />
      <button
        type="button"
        onClick={() => setShowModal(!showModal)}
        className="btn-arrow"
      >
        <FormattedMessage
          id="evs.helpMeChoose"
          defaultMessage="Help Me Choose"
          description="Help Me Choose"
        />
        {" >"}
      </button>

      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader className="bold" toggle={() => setShowModal(!showModal)}>
          <FormattedMessage
            id="evs.chargingAvailability"
            defaultMessage="Charging Availability"
            description="Charging Availability"
          />
        </ModalHeader>
        <ModalBody>
          <ChargingAvailabilityWizard toggle={() => setShowModal(!showModal)} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default SelectChargerLevel;

SelectChargerLevel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string
};
