import React from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import {
  ALL_STATIONS_TAB_ID,
  MAP_TABS,
  RANGE_MAP_TAB_ID,
} from "../../../constants/mapTabs";
import TravelRadiusMap from "../LocationMap/TravelRadiusMap/TravelRadiusMap";
import ChargingMap from "../LocationMap/ChargingMap/ChargingMap";
import { useIntl } from "react-intl";

import "./TabbedMaps.scss";

const activeClass = (tabNumber, activeTab) =>
  activeTab === tabNumber ? "active" : "";

const TabbedMaps = ({
  chargingStationsFilterFn,
  electricVehicles,
  oems,
  activeTabId,
  toggle,
  canIgnoreLowPowerStations = true,
  userLocation,
  dealerLocations,
  zipcode,
  evPage,
  pspsEvents = [],
}) => {
  const intl = useIntl()
  return (
    <div className="TabbedMaps">
      <div className="form-group d-md-none">
        <select
          value={activeTabId}
          onChange={(e) => toggle(e.target.value)}
          className="form-control"
        >
          {MAP_TABS.map((tab) => (
            <option key={tab.name} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="container">
        {/* {electricVehicles ? null : (
        <div className="titleContainer">
          {activeTabId === "tabbed-maps-all-stations-tab" ? (
            <>
              <h2 className="title">Locate Charging Stations</h2>
              <p className="subtitle">
                <FormattedMessage
                  id="ev.chargingStations.body"
                  defaultMessage="Charging stations Body"
                  description="Charging stations Body"
                  values={{
                    lineBreak: <br />,
                  }}
                />
              </p>
            </>
          ) : (
            <>
              <h2 className="title">Plan Your Route</h2>
              <p className="subtitle">
                <FormattedMessage
                  id="ev.planningRoute.body"
                  defaultMessage="Plan Route Body"
                  description="Plan Route Body"
                  values={{
                    lineBreak: <br />,
                  }}
                />
              </p>
            </>
          )}
        </div>
      )} */}
        <div className="tabbed-maps-panel">
          <Nav tabs className="d-none d-md-flex">
            {MAP_TABS.map((tab) => (
              <NavItem key={tab.name}>
                <NavLink
                  className={activeClass(tab.id, activeTabId)}
                  onClick={() => {
                    toggle(tab.id);
                  }}
                >
                  {intl.formatMessage ? intl.formatMessage({
                    id: tab.id,
                    defaultMessage: "",
                  }) : tab.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTabId}>
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED && (
              <TabPane tabId={ALL_STATIONS_TAB_ID}>
                <ChargingMap
                  userLocation={userLocation}
                  isVisible={activeTabId === ALL_STATIONS_TAB_ID}
                  zipcode={zipcode}
                />
              </TabPane>
            )}
            {process.env.REACT_APP_PAGES_RANGE_MAP_ENABLED && (
              <TabPane tabId={RANGE_MAP_TAB_ID}>
                <TravelRadiusMap
                  onlyTotalRange
                  chargingStationsFilterFn={chargingStationsFilterFn}
                  electricVehicles={electricVehicles}
                  isVisible={activeTabId === RANGE_MAP_TAB_ID}
                  canIgnoreLowPowerStations={false}
                  zipcodePlaceholder="Choose a location"
                  pspsEvents={pspsEvents}
                  showStationsByPowerLevel
                  showLegendCard={false}
                  hideStations
                  userLocation={userLocation}
                  btnStyles={{ width: "100%" }}
                  footerText=""
                />
              </TabPane>
            )}
          </TabContent>
        </div>
      </div>
    </div>
  );}

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
};

export default TabbedMaps;