import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import planet from "../../../assets/images/icons/Planet.svg"

import logo from "../../../assets/images/APS_logo.svg";
import "./Header.scss";
import { FormattedMessage } from "react-intl";

const Header = ({
  page,
  zipcode,
  activePage,
  userLocation,
  language,
  changeLanguage,
}) => {
  const [collapse, setCollapse] = useState(false);

  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

  const onChangeLanguage = (e) => {
    changeLanguage(e);
  };


  
  return (
    <div className="Header">
      <div className="container-fluid" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div
            style={{
              paddingTop: "0.75rem",
              paddingBottom: "0.75rem",
            }}
          >
            <Link to="/" className={isHomepage ? "active" : ""}>
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
                style={{ maxWidth: "200px" }}
              />
            </Link>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto mr-auto" navbar>
              <NavItem>
                <Link
                  to="/vehicles"
                  className={activePage === "vehicles" ? "active" : ""}
                >
                  <span>
                    <FormattedMessage
                      id="electricVehiclesHeader"
                      defaultMessage="Electric Vehicles"
                      description="Electric Vehicles Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/inventory"
                  className={activePage === "used-vehicles" ? "active" : ""}
                >
                  <span>
                    <FormattedMessage
                      id="localInventoryNavTitle"
                      defaultMessage="Local Inventory"
                      description="Pre-Owned EVs Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/compare-vehicles"
                  className={activePage === "compare-vehicles" ? "active" : ""}
                >
                  <span>
                    <FormattedMessage
                      id="compareVehicles"
                      defaultMessage="Compare Vehicles"
                      description="Compare Vehicles Header"
                    />
                  </span>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/charging-stations"
                  className={activePage === "map" ? "active" : ""}
                >
                  <span>
                    <FormattedMessage
                      id="chargingStations"
                      defaultMessage="Charging"
                      description="Charging Header"
                    />
                  </span>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
          <UncontrolledDropdown inNavbar>
            <DropdownToggle nav caret aria-label="Select a language">
              <span>
                <img src={planet} alt=""></img>
              </span>
            </DropdownToggle>
            <DropdownMenu
              style={{ color: "white", backgroundColor: "#0073B1" }}
            >
              <DropdownItem
                role="menuitem"
                className={language === "EN" ? "item active" : "off item"}
                onClick={() => onChangeLanguage("EN")}
              >
                EN
              </DropdownItem>
              <DropdownItem
                role="menuitem"
                className={language === "ES" ? "item active" : "off item"}
                onClick={() => onChangeLanguage("ES")}
              >
                ES
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Navbar>
        <div className="row bottomHeader d-none d-lg-flex">
          <div className="justify-content-center">
            <FormattedMessage
              id="header.subHeader"
              defaultMessage="Federal income tax credits may be available. Actual amounts vary. Consult your tax advisor. In the meantime, happy browsing!"
              description="SubHeader"
            />
          </div>
        </div>
        <div className="row bottomHeader mobile-top-header d-lg-none">
          <div className="justify-content-center p-0">
            <FormattedMessage
              id="header.subHeader"
              defaultMessage="Federal income tax credits may be available. Actual amounts vary. Consult your tax advisor. In the meantime, happy browsing!"
              description="SubHeader"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
