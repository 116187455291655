import React, { useState } from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "./../../utils/Helpers/Format";
import GaTracker from "./../../utils/GaTracker/GaTracker";
import { useIntl } from 'react-intl';

const IncentiveCard = props => {
  const intl = useIntl() 

  const [showMore, setShowMore] = useState(false);
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility
  
  if (eligibility === "ineligible") {
    return null
  }

  const isHardCode = incentive.name === "Alternative Fuel Infrastructure Tax Credit"

  let incentiveTypes = {
    "Bill Credit": intl.formatMessage ? intl.formatMessage({id:"billCredit", defaultMessage:"Bill Credit"}) : "Bill Credit",
    "Discounted Electric Rate": intl.formatMessage ? intl.formatMessage({id:"discountedElectricRate", defaultMessage:"Discounted Electric Rate"}) : "Discounted Electric Rate",
    "Financing": intl.formatMessage ? intl.formatMessage({id:"financing", defaultMessage:"Financing"}) : "Financing",
    "Free Equipment": intl.formatMessage ?intl.formatMessage({id:"freeEquipment", defaultMessage:"Free Equipment"}) : "Free Equipment",
    "HOV Lane": intl.formatMessage ? intl.formatMessage({id:"hovLane", defaultMessage:"HOV Lane"}) : "HOV Lane",
    "Insurance Discount": intl.formatMessage ? intl.formatMessage({id:"insuranceDiscount", defaultMessage:"Insurance Discount"}) : "Insurance Discount",
    "Parking Exemption": intl.formatMessage ? intl.formatMessage({id:"parkingExemption", defaultMessage:"Parking Exemption"}) : "Parking Exemption",
    "Public Charging": intl.formatMessage ? intl.formatMessage({id:"publicCharging", defaultMessage:"Public Charging"}) : "Public Charging",
    "Rebate": intl.formatMessage ? intl.formatMessage({id:"rebate", defaultMessage:"Rebate"}) : "Rebate",
    "Tax Credit": intl.formatMessage ? intl.formatMessage({id:"taxCredit", defaultMessage:"Tax Credit"}) : "Tax Credit",
    "Tax Exemption": intl.formatMessage ? intl.formatMessage({id:"taxExemption", defaultMessage:"Tax Exemption"}) : "Tax Exemption",
    "Toll Discount": intl.formatMessage ? intl.formatMessage({id:"tollDiscount", defaultMessage:"Toll Discount"}) : "Toll Discount",
}
  
  let description = incentive.description || "";
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount = incentive.evaluation &&  incentive.evaluation.amount_in_purchase >= 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : -1;

  const truncateText = (text, max = 25) => {
    if (!text) return "Nome";

    if (text.length > max) {
      return text.substring(0, max);
    } else return text;
  };

  const handleShowClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowMore((s) => !s);
  };

  const handleClick = () => {
    GaTracker.trackEvent({
      category: "Incentives",
      action: "Clicked on Incentive Card",
      label: `${incentive.name}`,
    });
  };

  let renderCard =
    incentive && !props.displayNone ? (
      <a
        className={`${props.displayNone ? "displayNone" : "IncentiveCard"} ${
          showMore ? "showAllCard" : ""
        }`}
        href={incentive.details_url}
        onClick={() => handleClick()}
      >
        <div className={`IncentiveCardTop`}>
          <span>
            {incentive.grantor + " - " + incentiveTypes[incentive.type]}
          </span>
        </div>
        <div className="IncentiveCardBody">
          <p className="h1 card-title">
            {isHardCode ? amountDescription : amount !== -1 
              ? FormatAsDollars(amount) 
              : amountDescription
            }
          </p>
          <p className="h2 light">{incentive.name || ""}</p>
        </div>
        <div className="pl-5 pr-5">
          <hr />
        </div>
        <p className="IncentiveCardBottom">
          {showMore ? description : truncateText(description, 114)}
          <button className="showBtn" onClick={handleShowClick}>
            {showMore ? " less" : "... more"}
          </button>
        </p>
      </a>
    ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool
};
