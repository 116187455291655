import React, {useState, useContext  } from "react";
import {ValidateFiveDigitCode} from "../../../utils/Helpers/USPostalCodeValidator"; 

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import getUserPref from "../../../context/UserPrefs/getUserPref";


import { FormattedMessage, useIntl } from 'react-intl';
import SelectVehiclePowerType from "../SelectPowerType/SelectPowerType";

const InputZipcodeForChargingMap = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  const [workingZipcode, setWorkingZipcode] = useState(getUserPref("zipcode"));

  const isInvalid = userPrefs.zipcodeIsNotFound && !userPrefs.zipcodeIsUpdating;

  const id = "input-zipcode-for-charging-map";
  const idAria = id + "-help";


  const handleUpdateButtonPress = (e, input) => {
    if (input && e.key !== "Enter") return
    if(ValidateFiveDigitCode(workingZipcode) && !userPrefs.zipcodeIsUpdating){
      
      async function asyncCall() {
        await userPrefs.set({
          workingZipcode: workingZipcode,
        });

        if (!userPrefs.zipcodeIsUpdating) {
          userPrefs.syncWorkingZipcode();
        }
      }
      asyncCall()
    }
  }

  return (
    <>
      <div className="map-options">
        <div className="form-group zip-options">
          <label htmlFor={id} id={idAria}>
            <FormattedMessage
              id="zipcode"
              defaultMessage="Zipcode"
              description="Zipcode"
            />
          </label>
          <div className="input-group mb-2 mr-sm-2">
            <input
              id={id}
              className={
                isInvalid
                  ? "form-control map-control is-invalid"
                  : "form-control map-control"
              }
              aria-describedby={idAria}
              value={workingZipcode}
              onChange={(e) => setWorkingZipcode(e.target.value)}
              onKeyDown={(e) => handleUpdateButtonPress(e, "input")}
            />
          </div>
        </div>
        <div className="form-group power-options">
          <SelectVehiclePowerType />
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn-ae btn-maps btn-default mb-2 btn-block"
            onClick={() => handleUpdateButtonPress()}
            disabled={userPrefs.zipcodeIsUpdating}
            id="update-zipcode-button"
          >
            {intl.formatMessage
              ? intl.formatMessage({
                  id: "chargingMap.searchCharging",
                  defaultMessage: "Update ZIP Code",
                })
              : "Update ZIP Code"}
          </button>
        </div>
      </div>
      {(isInvalid || !ValidateFiveDigitCode(workingZipcode)) && (
        <div className="text-danger small">
          Error: {workingZipcode} is not a valid zipcode
        </div>
      )}
    </>
  );
};

export default InputZipcodeForChargingMap;
