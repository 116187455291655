import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import EVCard from "../../../components/EVCard/EVCard"
import { FormattedMessage } from 'react-intl';

const VehicleCarousel = ({ electricVehicles, userLocation }) => {
  const [selectedEv, setSelectedEv] = useState(1);
  const [firstEv] = useState(
    Math.floor(electricVehicles.length * Math.random())
  );

  if (!electricVehicles || electricVehicles.length <= 3) return null;
  if (!userLocation) return null

  const vehicleCount = electricVehicles ? electricVehicles.length : "many";

  const MNGoldDealers = new Set(["Mitsubishi", "Nissan", "Chevrolet", "Ford", "Volkswagen", "Toyota", "Tesla", "Jaguar", "Land Rover", "Volvo"])

  const COGoldDealers = new Set(["Audi", "Nissan", "Ford", "Volkswagen", "Hyundai", "Kia", "Tesla", "Jaguar"])

  const stateGoldDealers = {
    "Minnesota": MNGoldDealers,
    "Colorado": COGoldDealers,
  }

  if (stateGoldDealers[userLocation.region]) {
    electricVehicles = electricVehicles.filter(ev => {
      return stateGoldDealers[userLocation.region].has(ev.make)
    })
  }

  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount
  ];

  const filteredEVs = [
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
    electricVehicles[evIndices[3]]
  ];

  const selectableEvs = filteredEVs.map((ev, i) => {
    return (
      <div
        onClick={() => setSelectedEv(i)}
        onKeyPress={() => setSelectedEv(i)}
        className={`${"evc-card EVCard " +
          (selectedEv === i ? " selected" : "not-selected")}`}
        tabIndex="0"
        key={i}
      >
        <EVCard
          ev={ev}
          hasEvDetails={true}
          hasLinkToEv={selectedEv === i}
          hasLocallyAvailableHidden
        />
      </div>
    );
  });

  const linkstoEvs = filteredEVs.map((ev, i) => {
    return (
      <Link
        to={`${"/vehicles/" + ev.handle}`}
        className="evc-card EVCard"
        key={i}
      >
        <EVCard ev={ev}  hasLocallyAvailableHidden />
      </Link>
    );
  });

  const renderVehicles = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex justify-content-center">
        <div className="col-sm-1 text-right">
        </div>
        <div className="col-sm-10">
          <div className="render-cards-container full-width">
            {selectableEvs}
          </div>
        </div>
        <div className="col-sm-1">
        </div>
      </div>

      <div className="render-cards-container full-width d-flex d-lg-none d-xl-none">
        {linkstoEvs}
      </div>
    </>
  );

  return (
    <section className="container-fluid" id="HomepageVehiclesCarousel">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h2 className="title-head">
            <FormattedMessage
              id="homepage.vehicleCarousel.title"
              defaultMessage="There are more electric vehicles available than ever. Discover yours."
              description="Vehicle Carousel Title"
            />
          </h2>
          <h3 className="title-sub mb-20" style={{ fontWeight: "400"}}>
            <FormattedMessage
              id="homepage.vehicleCarousel.subTitle"
              defaultMessage="Compare the cost of your selection to a similar gas vehicle. An electric car can save you money {lineBreak}because it can be less expensive to own and maintain than a 100% gasoline-powered car."
              description="Vehicle Carousel Title"
              values={{
                lineBreak: <br />
              }}
            />
          </h3>
          <Link to="/vehicles" style={{ margin: "12px", textDecoration: "none" }} className="btn btn-aps mt-10" role="button">
            <FormattedMessage
              id="findYourElectricVehicle"
              defaultMessage="Find Your Electric Vehicle"
              description="Find Your Electric Vehicle"
            />
          </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">{renderVehicles}</div>
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object
};
