import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { FormattedMessage } from 'react-intl';
import FacebookIcon from "../../../assets/images/icons/Facebook.svg"
import TwitterIcon from "../../../assets/images/icons/Twitter.svg"
import InstagramIcon from "../../../assets/images/icons/Instagram.svg"
import LinkedinIcon from "../../../assets/images/icons/Linkedin.svg"
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

const Footer = ({ language, changeLanguage }) => {


  return (
    <div className="Footer">
      <div className="container-fluid pb-4">
        <div className="row local-links pb-4">
          <div className="left-info">
            <span className="subtext">
              Copyright &copy; 2022 {process.env.REACT_APP_FULL_COMPANY_NAME}.{" "}
            </span>{" "}
            <span
              className="subtext"
              style={{ fontFamily: "Arial, Helvetica" }}
            >
              {" "}
              &copy; All rights reserved.{" "}
            </span>
            {/* <span className="subtext" style={{ marginLeft: "5px" }}>
              {" "}
              Powered by
              <span className="subtext" style={{ marginLeft: "5px" }}>
                <img style={{ width: "120px" }} src={zappy} alt=""></img>{" "}
              </span>
              <span className="subtext">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://zappyride.com/legal-notices"
                >
                  Legal notices.
                </a>
              </span>
            </span> */}
          </div>
          <div className="d-flex">
            {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
              <>
                <Link to="/vehicles">
                  <FormattedMessage
                    id="electricVehicles"
                    defaultMessage="Electric Vehicles"
                    description="Electric Vehicles Footer"
                    values={{
                      electricVehicles:
                        process.env.REACT_APP_PAGES_VEHICLES_TITLE,
                    }}
                  />
                </Link>
              </>
            ) : null}
          </div>
          <div className="line">{"|"}</div>
          <div className="d-flex">
            {process.env.REACT_APP_PAGES_INVENTORY_ENABLED ? (
              <>
                <Link to="/used-vehicles">
                  <FormattedMessage
                    id="localInventory"
                    defaultMessage="Local Inventory"
                    description="Local Inventory Footer"
                  />
                </Link>
              </>
            ) : null}
          </div>
          <div className="line">{"|"}</div>
          <div className="d-flex">
            {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
              <>
                <Link to="/compare-vehicles">
                  <FormattedMessage
                    id="compareVehicles"
                    defaultMessage="Compare Vehicles"
                    description="Compare Vehicles Footer"
                    values={{
                      compareVehicles:
                        process.env.REACT_APP_PAGES_COMPARE_TITLE,
                    }}
                  />
                </Link>
              </>
            ) : null}
          </div>
          <div className="line">{"|"}</div>
          <div className="d-flex">
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
              <>
                <Link to="/charging-stations">
                  <FormattedMessage
                    id="chargingStations"
                    defaultMessage="Charging Stations"
                    description="Charging Stations Footer"
                    values={{
                      chargingStations:
                        process.env.REACT_APP_PAGES_CHARGING_TITLE,
                    }}
                  />
                </Link>
              </>
            ) : null}
          </div>
          <div className="social-media-row">
            <a
              href="https://www.facebook.com/XcelEnergyMN"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-media-icon"
                alt="Facebook Icon"
                src={FacebookIcon}
              />
            </a>
            <a
              href="https://www.instagram.com/xcelenergy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-media-icon"
                alt="Instagram Icon"
                src={InstagramIcon}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/xcel-energy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-media-icon"
                alt="Linkedin Icon"
                src={LinkedinIcon}
              />
            </a>
            <a
              href="https://twitter.com/XcelEnergyMN"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-media-icon"
                alt="Twitter Icon"
                src={TwitterIcon}
              />
            </a>
          </div>
        </div>
        <div className="row pb-3 more-info">
          <div className="col-md-12">
            <span>
              <FormattedMessage
                id="footerAdditional"
                defaultMessage="This is a third party website owned, controlled and managed by ZappyRide, not Arizona Public Service Company (APS). ZappyRide may share or otherwise provide access to Non-Personally Identifiable Information to APS in connection with ZappyRide’s operation and management of the Site. APS’s privacy policy governs its collection, receipt, and use of such information on {link} and is available here. "
                description="Additonal Information"
                values={{
                  link: (
                    <a className="aps-link" href="https://aps.com">
                      {" "}
                      aps.com
                    </a>
                  ),
                }}
              />
            </span>
            <div className="disclaimer-container">
              <DisclaimerComponent clientName="zappyride" 
              imageStyle={{marginLeft:"0px"}}
              wrapperStyle={{position:"0px"}}
              textStyle={{fontFamily:"Gotham Book", fontSize:"13.5px"}} />
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
