import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Select from "../../shared/InputElements/Select";
import { useIntl } from "react-intl";

const SelectVehiclePowerType = ({
  id = "select-vehicle-power-filter",
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage
    ? intl.formatMessage({ id: "vehicle.type", defaultMessage: "TYPE" })
    : "TYPE";

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleTypeFilter")}
      label={label}
      optionNames={[
        intl.formatMessage
          ? intl.formatMessage({
              id: "vehicle.power.all",
              defaultMessage: "All",
            })
          : "All",
        intl.formatMessage
          ? intl.formatMessage({
              id: "vehicle.power.high",
              defaultMessage: "High",
            })
          : "High",
        intl.formatMessage
          ? intl.formatMessage({
              id: "vehicle.power.public",
              defaultMessage: "Public",
            })
          : "Public",
        intl.formatMessage
          ? intl.formatMessage({
              id: "vehicle.power.other",
              defaultMessage: "Other",
            })
          : "Other",
      ]}
      optionValues={["All", "highPower", "public", "default"]}
      handler={(e) =>
        userPrefs.set({ vehicleTypeFilter: e.target.value })
      }
    />
  );
};

export default SelectVehiclePowerType;

SelectVehiclePowerType.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
