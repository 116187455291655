import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Switch from "react-switch";

import ToolTip from "../../../components/shared/ToolTip/ToolTip";
// import IconLocal from "../../assets/images/icons/icon-local.png";
import IconBEV from "../../assets/images/icons/Electric.svg";
import IconPHEV from "../../assets/images/icons/PHEV.svg";
import { useIntl, FormattedMessage } from "react-intl";

const EVFilterControls = ({ vehicles }) => {
  const intl = useIntl()  
  const userPrefs = useContext(UserPrefsContext);

  const formFactorFilterData = userPrefs.get("vehicleFormFactorFilters");
  const fuelTypeFilterData = userPrefs.get("vehicleFuelTypeFilters");


  const updateFormFactorFilter = (e, key) => {
    e.preventDefault();
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];
    userPrefs.set({
      vehicleFormFactorFilters: newFilters,
    });
  };

  const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName =
        filterKey === "suv"
          ? "SUV"
          : `${filterKey.charAt(0).toUpperCase()}${filterKey.substr(1)}`;

      return (
        <button
          className={formFactorFilterData[filterKey] ? "btn active" : "btn"}
          key={i}
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={(e) => updateFormFactorFilter(e, filterKey)}
        >
          {filterName}
        </button>
      );
    }
  );

  const updateFuelTypeFilter = (checked, event, selectedKey) => {
    const newFilters = Object.keys(fuelTypeFilterData).reduce(function (
      acc,
      key
    ) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !fuelTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
      vehicleFuelTypeFilters: newFilters,
    });
  };

  const renderedFuelTypeFilters = Object.keys(fuelTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      let filterIconSrc = "";
      switch (filterKey) {
        case "bev":
          filterName = intl.formatMessage
            ? intl.formatMessage({
                id: "vehicle.allElectric",
                defaultMessage: "All Electric",
              })
            : "All Electric";
          filterIconSrc = IconBEV;
          break;
        case "phev":
           filterName = intl.formatMessage
             ? intl.formatMessage({
                 id: "vehicle.plugInHybrid",
                 defaultMessage: "Plug in Hybrid",
               })
             : "Plug in Hybrid";
          filterIconSrc = IconPHEV;
          break;
        default:
      }

      return (
        <div key={i} className="filter-switch">
          <div className={i !== 0 ? "filter-label" : "filter-label first"}>
            <span className="badge">
              <img style={filterKey.toLowerCase() === "phev" ? {width: "50px" }: null}src={filterIconSrc} alt="" />
            </span>
            <span className="name">{filterName}</span>
            <div className="switch">
              <Switch
                title={filterName}
                id={filterKey}
                checked={fuelTypeFilterData[filterKey] ? true : false}
                onChange={updateFuelTypeFilter}
                onColor="#0081C6"
                offColor="#73767B"
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      );
    }
  );

  return (
    <div>
      <form>
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage
              id="evfilter.fuel"
              defaultMessage="Fuel"
              description="Fuel"
            />
            <ToolTip
              message={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: "evfilter.fuelTooltip",
                      defaultMessage:
                        "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline.",
                    })
                  : "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline."
              }
              id="type_tooltip"
            />
          </span>
          <div className="btn-block-container">{renderedFuelTypeFilters}</div>
        </div>
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage
              id="evfilter.type"
              defaultMessage="Type"
              description="Type"
            />
          </span>
          <ToolTip
            message={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "vehicleType",
                    defaultMessage:
                      "Select all vehicle body types you are interested in.",
                  })
                : "Select all vehicle body types you are interested in."
            }
            id="fuel_tooltip"
          />
          <div className="btn-grid-container">{renderedFormFactorFilters}</div>
        </div>
      </form>
    </div>
  );
};

export default EVFilterControls;
