import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "./../../components/IncentiveCatalog/IncentiveCatalog";
// import IncentivePreferences from "./../../components/IncentivesPreferences/IncentivePreferences";
import IncentiveSortControls from "../../client_customizations/components/IncentiveSortControls/IncentiveSortControls";
import IncentiveScoreOptions from "../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage } from 'react-intl';
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";


const Incentives = ({ electricVehicles, incentives, userLocation }) => {
  const userPrefs = useContext(UserPrefsContext);
  
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );
  const renderOptions = electricVehicles ? (
    <>
      <IncentiveScoreOptions userLocation={userLocation} electricVehicles={electricVehicles}/>
      <br />
    </>
  ) : null;

  const vehicleIsSelected = userPrefs.get("vehicleModelFilter") && userPrefs.get("vehicleMakeFilter")  && userPrefs.get("vehicleModelFilter") !== 'All' && userPrefs.get("vehicleMakeFilter") !== 'All';

  return (
    <>
      <section className="container-fluid-tight" id="incentives">
        <div className="row mb-3">
          <div className="col-sm-12 text-center">
            <h1 className="evHead">
              <FormattedMessage
                id="incentives.title"
                defaultMessage="Electric vehicle incentives"
                description="Electric vehicle incentives"
              />
            </h1>
            <p className="lead">
              <FormattedMessage
                id="incentives.subTitle"
                defaultMessage="Adjust the filters to display incentives and personalize your results"
                description="Description of Platte River"
                values={{
                  lineBreak: <br />,
                }}
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="pull-right">
              <IncentiveSortControls />
            </div>
          </div>
        </div>
        <div className="row main-container">
          <div className="col-xl-3 col-lg-4 d-block">
            {renderOptions}
          </div>
          <div
            className="col-xl-9 col-lg-8 col-sm-12"
            style={{ paddingLeft: 10 }}
          >
            {vehicleIsSelected && renderIncentives}
          </div>
        </div>
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
