const ALL_STATIONS_TAB_NAME = "Charging Stations";
const ALL_STATIONS_TAB_URL = "/charging-stations";
export const ALL_STATIONS_TAB_ID = "tabbed-maps-all-stations-tab";

const RANGE_MAP_TAB_NAME = "Range Map";
const RANGE_MAP_TAB_URL = "/range-map";
export const RANGE_MAP_TAB_ID = "tabbed-maps-range-tab";

export const MAP_TABS = [
  {
    id: ALL_STATIONS_TAB_ID,
    name: ALL_STATIONS_TAB_NAME,
    url: ALL_STATIONS_TAB_URL,
    title: process.env.REACT_APP_PAGES_CHARGING_TITLE,
    description: "Find local public charging stations",
    isEnabled: process.env.REACT_APP_PAGES_CHARGING_ENABLED,
    activePage: "map"
  },
  {
    id: RANGE_MAP_TAB_ID,
    name: RANGE_MAP_TAB_NAME,
    url: RANGE_MAP_TAB_URL,
    title: process.env.REACT_APP_PAGES_RANGE_MAP_TITLE,
    description: "Find a Range",
    isEnabled: process.env.REACT_APP_PAGES_RANGE_ROUTE_ENABLED
  },
];
