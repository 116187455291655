import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import EVJumbotron from "../../client_customizations/components/EVJumbotron/EVJumbotron"
import EVReviews from "./../../components/EVReviews/EVReviews";
import MapPage from "../../client_customizations/pages/MapPage/MapPage";
import CostOfOwnership from "../../client_customizations/components/CostOfOwnership/CostOfOwnership";
import getPaymentDetails from "./../../functions/vehicle/getPaymentDetails";

import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormatCarName } from "../../utils/Helpers/Format";


const EV = ({
  match,
  electricVehicles,
  dealerLocations,
  userLocation,
  tabId,
  ip,
  uuid,
  zipcode
}) => {
  const userPrefs = useContext(UserPrefsContext);

  let car = null;
  let paymentDetails = {};

  if (electricVehicles) {
    const evId = match ? match.params["evId"] : 0;
    car = electricVehicles.find(ev => ev.handle === evId);
    paymentDetails = getPaymentDetails(
      car,
      userPrefs.get("monthsOfOwnership"),
      userPrefs.get("interestRateAsBasisPoints"),
      userPrefs.get("milesDrivenAnnually"),
      userPrefs.get("salesTax"),
    );
  }

  useEffect(() => {
    document.title = car
      ? `${FormatCarName(car)}`
      : "";
  });

  const renderEV = electricVehicles ? (
    <>
      <EVJumbotron
        car={car}
        incentives={car.incentives}
        paymentDetails={paymentDetails}
      />
      <CostOfOwnership
        cars={[car, car.equivalent_gas_vehicle]}
        userLocation={userLocation}
      />
      <div className="container-fluid" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="container pb-4">
          <p className="h1 pt-4 mb-3 text-center"></p>
          <div
          >
            <MapPage
              userLocation={userLocation}
              tabId={tabId}
              zipcode={userPrefs.get("zipcode")}
              electricVehicles={electricVehicles}
              dealerLocations={dealerLocations}
              homePage={true}
            />
          </div>
        </div>
      </div>

      <EVReviews reviews={car.video_reviews} />
    </>
  ) : (
    <div className="container text-center">
      <LoadingSpinner />
    </div>
  );


  return (
    <>
      {renderEV}
    </>
  );
};

export default EV;

EV.propTypes = {
  match: PropTypes.object,
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
