import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import { FormatAsDollars, FormatCarName } from "../../utils/Helpers/Format";
import calcTotalCostOfOwnership from "../../functions/vehicle/CostOfOwnership/calcTotalCostOfOwnership";
import { VehicleCost } from "../../functions/vehicle/CostOfOwnership/calcs";

import {
  SALES_TAX,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
} from "../../client_customizations/data/assumptions/ASSUMPTIONS";
import getCarCostOfOwnership from "../../client_customizations/components/CostOfOwnership/getCarCostOfOwnership";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import times from "lodash/times";
import { FormattedMessage, useIntl } from 'react-intl';



const CHART_JS_OPTIONS = {
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const value = FormatAsDollars(
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        );
        const label = data.datasets[tooltipItem.datasetIndex].label || "";
        return " " + label + ": " + value;
      },
      title: function (tooltipItem) {
        return "year " + tooltipItem[0].xLabel;
      },
    },
  },
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        ticks: {
          font: {
            weight: 700,
          },
          beginAtZero: false,
          callback: function (value) {
            return FormatAsDollars(value);
          },
        },
      },
    ],
  },
  legend: {
    position: "bottom",
    onClick: function (e) {
      e.stopPropagation();
    },
  },
  plugins: {
    datalabels: {
      // chartjs-plugin-datalabels is enabled on this project,
      // so without returning "" the raw values will be shown for each data point
      formatter: () => "",
      align: "end",
      anchor: "end",
      color: "#333333",
      font: {
        weight: 700,
      },
    },
  },
};

const breakevenCosts = (car, get, yearsOfOwnership, insuranceData, maintenanceData) => {
  return yearsOfOwnership.map((year) => {
    switch (year) {
      case 0:
        return parseInt(VehicleCost.afterIncentives(car));

      case yearsOfOwnership.length - 1:
        return calcTotalCostOfOwnership(
          car,
          "cash",
          year * 12,
          get("milesDrivenAnnually"),
          get("interestRateAsBasisPoints"),
          get("electricMilesPortionForPhev"),
          get("gasolinePriceInCentsPerGal"),
          get("includeResaleValue"),
          process.env.REACT_APP_SLIDE_SALES_TAX
            ? get("salesTax")
            : SALES_TAX.value,
          process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING
            ? get("costPublicCharging")
            : 0,
          process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING
            ? get("publicChargingPercentage")
            : 0,
          process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE
            ? get("electricityRate")
            : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value,
          process.env.REACT_APP_DYNAMIC_INSURANCE_COST
            ? get("municipality")
            : null,
          insuranceData,
          maintenanceData
        ).summed.total;

      default:
        return calcTotalCostOfOwnership(
          car,
          "cash",
          year * 12,
          get("milesDrivenAnnually"),
          get("interestRateAsBasisPoints"),
          get("electricMilesPortionForPhev"),
          get("gasolinePriceInCentsPerGal"),
          false, // do not include resale value in intermediate years
          process.env.REACT_APP_SLIDE_SALES_TAX
            ? get("salesTax")
            : SALES_TAX.value,
          process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING
            ? get("costPublicCharging")
            : 0,
          process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING
            ? get("publicChargingPercentage")
            : 0,
          process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE
            ? get("electricityRate")
            : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value,
          process.env.REACT_APP_DYNAMIC_INSURANCE_COST
            ? get("municipality")
            : null,
            insuranceData,
            maintenanceData
        ).summed.total;
    }
  });
};

const BreakevenChart = ({
  cars,
  forceUserPrefsPresets,
  onAnimationComplete,
  insuranceData,
  maintenanceData
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();

  if (!cars || cars.length === 0) return null;

  cars = cars.filter((car) => {
    return car !== null;
  });

  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;

  const yearsOfOwnership = times(get("monthsOfOwnership") / 12 + 1, (i) => i);

  const carCosts = cars.map((car) => {
    return breakevenCosts(car, get, yearsOfOwnership, insuranceData, maintenanceData);
  });
  const carTotalCosts = cars.map((car) => {
    return getCarCostOfOwnership(car, userPrefs, insuranceData, maintenanceData);
  });
  const costDelta =
    carTotalCosts[1].summed.total - carTotalCosts[0].summed.total;

  const priceChange = (carCosts) => {
    let years = [];
    for (let i = 0; i < carCosts[0].length; i++) {
      if (carCosts[0][i] < carCosts[1][i]) {
        years.push(i);
      }
    }
    if (years.length === carCosts[0].length) {
      return -1;
    } else if (years.length === 0) {
      return -2;
    }
    return years[0];
  };

  const renderSwitch = (param) => {
    switch (param) {
      case -2:
        return (
          <FormattedMessage
            id="tcoSavingsHeading"
            defaultMessage="The {car1} does not currently costs less to own than your existing vehicle"
            description="Cost to Breakdown Subtitle"
            values={{
              car1: FormatCarName(cars[0]),
            }}
          />
        );
      case -1:
        return (
          <FormattedMessage
            id="graph.costToBreakdown.subTitleAlwaysCheaper"
            defaultMessage="The {car1} currently costs {cost} less to own than your existing vehicle"
            description="Cost to Breakdown Subtitle"
            values={{
              cost: (
                <strong style={{ color: "rgb(33, 135, 57)", fontWeight: 800 }}>
                  {FormatAsDollars(Math.abs(costDelta))}
                </strong>
              ),
              car1: FormatCarName(cars[0]),
            }}
          />
        );
      default:
        return (
          <FormattedMessage
            id="graph.costToBreakdown.subTitleCheaper"
            defaultMessage="The {car1} costs {cost} less to own after {time}"
            description="Cost to Breakdown Subtitle"
            values={{
              cost: (
                <strong style={{ color: "rgb(33, 135, 57)", fontWeight: 800 }}>
                  {FormatAsDollars(Math.abs(costDelta))}
                </strong>
              ),
              car1: FormatCarName(cars[0]),
              time:
                priceChange(carCosts) === 1
                  ? " 1 year"
                  : `${priceChange(carCosts)} years`,
            }}
          />
        );
    }
  };
  let subTitle = renderSwitch(priceChange(carCosts));

  let datasets = [
    {
      data: carCosts[1],
      label: FormatCarName(cars[1]),
      borderColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_GASOLINE}`,
      pointBackgroundColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_GASOLINE}`,
      lineTension: 0,
      fill: false,
    },
    {
      data: carCosts[0],
      label: FormatCarName(cars[0]),
      borderColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_ELECTRICITY}`,
      backgroundColor: "rgba(0,169,206, 0.5)",
      fill: "-1",
      pointBackgroundColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_ELECTRICITY}`,
      lineTension: 0,
    },
  ];

  if (cars.length === 3) {
    subTitle = (
      <p className="h3 my-3 graph-title">
        <FormattedMessage
          id="graph.costToBreakdown.subTitleThreeCars"
          defaultMessage="Compare the cumulative lifetime cost of {car1}, {car2} and {car3}"
          description="Cost to Breakdown Subtitle 3 Cars"
          values={{
            car1: FormatCarName(cars[0]),
            car2: FormatCarName(cars[1]),
            car3: FormatCarName(cars[2]),
          }}
        />
      </p>
    );
    datasets.push({
      data: carCosts[2],
      label: FormatCarName(cars[2]),
      borderColor: `#0000ff`,
      backgroundColor: "transparent",
      lineTension: 0,
      borderDash: [10, 10],
    });
  }

  const title = (
    <h3>
      <div className="graph-title">{subTitle}</div>
    </h3>
  );

  const chartData = {
    labels: yearsOfOwnership,
    datasets: datasets,
  };

  const chartOptions = Object.assign({}, CHART_JS_OPTIONS, {
    animation: {
      onComplete: onAnimationComplete,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  });

  return (
    <div className="BreakevenChart input-well text-center">
      {title}
      <div className="line-box chart-container d-none d-lg-block d-xl-block">
        <div className="chart-info">
          <Line
            data={chartData}
            type="line"
            options={chartOptions}
            height={250}
          />
        </div>
        <div className="savings-info">
          {"$" +
            `${Math.floor(costDelta)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` +
            (
              intl.formatMessage({ id: "savings", defaultMessage: " in savings"})
             
            )}
        </div>
      </div>
    </div>
  );
};

export default BreakevenChart;

BreakevenChart.propTypes = {
  car: PropTypes.object,
  comparedCar: PropTypes.object,
  forceUserPrefsPresets: PropTypes.bool,
  onAnimationComplete: PropTypes.func,
};