import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import LinkCard from "../../LinkCard/LinkCard"

const HomepageBigPromise = ({
  homePageBannerImage,
  userLocation
}) => {
  return (
    <>
      <section
        className="container-fluid homepage-jumbotron"
        style={{
          backgroundImage: "url(" + homePageBannerImage + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
          WebkitBackgroundSize: "50%",
          MozBackgroundSize: "50%",
          OBackgroundSize: "50%",
          backgroundSize: "contain",
          height: "575px",
          padding: 0,
        }}
      >
        <div className="big-promise">
          <h1 className="mt-4 homepage-header">
            <FormattedMessage
              id="yourGuide"
              defaultMessage="Your guide to electric vehicles."
              description="Your guide to electric vehicles."
            />
            <br />
          </h1>
          <h3 className="homepage-subheader">
            <FormattedMessage
              id="estimate"
              defaultMessage="Estimate and Compare costs, savings &amp; more."
              description="Estimate and Compare costs, savings &amp; more."
            />
          </h3>
          <Link to="/vehicles">
            <button className="btn btn-aps" style={{ marginTop: "10px" }}>
              <FormattedMessage
                id="homepage.findYour"
                defaultMessage="Find Your EV"
                description="Find Your EV"
              />
            </button>
          </Link>
        </div>
      </section>
      <section>
        <div className="grey" id="link-card-buttons">
          <div className="row">
            <div className="grey link-card-container">
              <LinkCard type="BROWSE_VEHICLES" />
              <LinkCard type="COMPARE_VEHICLES" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
  userLocation: PropTypes.string
}