const filterEVs = (evs, filterParams) => {
  const {
    vehicleFormFactorFilters,
    vehicleFuelTypeFilters,
    vehicleMakeFilter,
    vehicleAgeFilter,
    vehicleBudgetFilter,
    vehicleRangeFilter
  } = filterParams || {};

  const allowedFuelTypes = Object.keys(vehicleFuelTypeFilters).filter(
    (key) => vehicleFuelTypeFilters[key]
  );

  const allowedFormFactors = Object.keys(vehicleFormFactorFilters).filter(
    (key) => vehicleFormFactorFilters[key]
  );

  return evs
    .filter(
      ({ make }) => vehicleMakeFilter === "All" || vehicleMakeFilter === make
    )
    .filter(
      ({ fuel }) =>
        allowedFuelTypes.length === 0 ||
        allowedFuelTypes.includes(fuel.toLowerCase())
    )
    .filter(
      ({ form_factor }) =>
        allowedFormFactors.length === 0 ||
        allowedFormFactors.includes(form_factor.toLowerCase())
    )
    .filter(({ model_year }) => {
      if (vehicleAgeFilter === "0-1") {
        return new Date().getFullYear() - model_year < 2;
      }
      if (vehicleAgeFilter === "2-3") {
        return (
          1 < new Date().getFullYear() - model_year &&
          new Date().getFullYear() - model_year < 4
        );
      }
      if (vehicleAgeFilter === "4+") {
        return new Date().getFullYear() - model_year > 3;
      }
      return true;
    })
    .filter(({ msrp }) => msrp <= vehicleBudgetFilter)
    .filter(({ total_range }) => total_range >= vehicleRangeFilter);;
};

export default filterEVs;
