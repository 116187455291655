import React from "react";
import "./PspsEventLegend.scss";

const PspsEventLegend = () => (
  <div className="d-flex PspsEventLegend">
    <span className="shape-icon" />
    <span>PSPS event area</span>
  </div>
);

export default PspsEventLegend;
