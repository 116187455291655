import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import unique from "lodash/uniq";
import { FormattedMessage, useIntl } from "react-intl";

import Select from "../shared/InputElements/Select";

const SelectVehicleModelYearFilter = ({
  id = "select-vehicle-model-year-filter",
  vehicles,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const make = userPrefs.get("vehicleMakeFilter");
  const model = userPrefs.get("vehicleModelFilter");
  const yearStart = userPrefs.get("vehicleYearStartFilter");
  const yearEnd = userPrefs.get("vehicleYearEndFilter");

  const intl = useIntl();

  let olderstLabel = intl && intl.formatMessage
    ? intl.formatMessage({
        id: "inventory-filter.oldest",
        defaultMessage: "Oldest",
      })
    : "";

  let newestLabel =
    intl && intl.formatMessage
      ? intl.formatMessage({
          id: "inventory-filter.newest",
          defaultMessage: "Newest",
        })
      : "";

  let filteredVehicles = unique(
    (vehicles || [])
      .filter(
        (ev) =>
          (make !== "All" ? ev.make === make : true) &&
          (model !== "All" ? ev.model === model : true)
      )
      .map((v) => v.model_year)
      .sort()
  );

  let vehicleYearOptionsStart = filteredVehicles.slice(
    0,
    yearEnd !== "All"
      ? filteredVehicles.indexOf(Number(yearEnd)) + 1
      : filteredVehicles.length
  );

  let vehicleYearOptionsEnd = filteredVehicles.slice(
    yearStart !== "All" ? filteredVehicles.indexOf(Number(yearStart)) : 0,
    filteredVehicles.length
  );

  let modelYearsLabel = (
    <FormattedMessage
      id="inventory-filter.model-years-label"
      defaultMessage="Model Years"
      description="Model Years"
    />
  );

  let modelYearsToLabel = (
    <FormattedMessage
      id="inventory-filter.model-years-to-label"
      defaultMessage="TO"
      description="TO"
    />
  );

  return (
    <div className="d-flex flex-column ">
      <label>{modelYearsLabel}</label>
      <div
        className="d-flex align-items-center"
        style={{ width: "100%", marginBottom: "-10px" }}
      >
        <div style={{ width: "100%" }}>
          <Select
            id={id}
            ariaLabel={modelYearsLabel}
            value={userPrefs.get("vehicleYearStartFilter")}
            optionNames={[olderstLabel, ...vehicleYearOptionsStart]}
            optionValues={["All", ...vehicleYearOptionsStart]}
            handler={(e) =>
              userPrefs.set({ vehicleYearStartFilter: e.target.value })
            }
          />
        </div>
        <label className="mx-2">{modelYearsToLabel}</label>
        <div style={{ width: "100%" }}>
          <Select
            id={id}
            ariaLabel={modelYearsLabel}
            value={userPrefs.get("vehicleYearEndFilter")}
            optionNames={[newestLabel, ...vehicleYearOptionsEnd]}
            optionValues={["All", ...vehicleYearOptionsEnd]}
            handler={(e) =>
              userPrefs.set({ vehicleYearEndFilter: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SelectVehicleModelYearFilter;

SelectVehicleModelYearFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
