import {
  ATTACHED_GARAGE,
  BASEMENT_PANEL_LOCATION,
  BUNDLED_RATE_TYPE_ID,
  HOME_ENERGY_COST_ID
} from '../../client_customizations/constants/';

import chargerPaymentOptions from '../../client_customizations/data/chargerPaymentOptions';
import chargingLocations from '../../client_customizations/data/chargingLocations';
import electricPanelLocations from '../../client_customizations/data/electricPanelLocations';
import electricPanelLocationRoomTypes from '../../client_customizations/data/electricPanelLocationRoomTypes';
import energyCostTypes from '../../client_customizations/data/energyCostTypes';
import states from './../../client_customizations/data/states';
import { NEW_CONDITION_ID } from '../../client_customizations/constants/vehicleConditions';

const USER_PREF_PRESETS = {
  canTurnInClunker: false,
  chargerInstallAllowed: null,
  chargerLevel: "level_2",
  electricMilesPortionForPhev: 60,
  hasOvernightCharger: true,
  electricityProvider: "APS",
  gasolinePriceInCentsPerGal: 210,
  hasRegularPowerOutlet: null,
  householdIncome: 75000,
  householdSize: 1,
  vehicleTypeFilter: "All",
  includeResaleValue: true,
  interestRateAsBasisPoints: 350,
  locallyAvailableVehicleFilter: false,
  maxBudget: 60000,
  milesDrivenAnnually: 12000,
  milesDrivenDaily: 30,
  minSeats: 2,
  monthsOfOwnership: 60,
  parkingLocation: "street",
  purchaseMethod: "cash",
  showAllRates: false,
  showGasolineCost: true,
  showHomeElectricityCost: true,
  showRateDetails: true,
  showDetailsForRate: "EV2-A",
  taxFilingStatus: "single",
  vehicleChargingPattern: "After midnight; Before 3pm",
  vehicleIdForIncentives: "",
  vehicleHandleForIncentives: "",
  vehicleSortDirection: "desc",
  vehicleSortType: "match_score",
  incentiveSortDirection: "desc",
  incentiveSortType: "price",
  workingZipcode: `${process.env.REACT_APP_DEFAULTS_POSTAL_CODE}`,
  zipcode: `${process.env.REACT_APP_DEFAULTS_POSTAL_CODE}`,
  chargerBudget: 2000,
  chargerCordLength: 25,
  chargerSortType: "price",
  chargerSortDirection: "asc",
  electricityRate: 0.1131,
  salesTax: 0.06875,
  eventLocation: "All Locations",
  eventsSortType: "date-asc",

  vehicleMakeFilter: "All",
  vehicleAgeFilter: "All",
  vehicleModelFilter: "All",
  // vehicleCondition: "New",
  vehicleYearStartFilter: "All",
  vehicleYearEndFilter: "All",
  vehicleDistanceFilter: null,
  vehicleSelectedDealer: "All",

  vehicleInsuranceYearly: null,

  inventoryDealers: {},

  vehicleFuelTypeFilters: {
    bev: false,
    phev: false,
  },

  vehicleFormFactorFilters: {
    sedan: false,
    hatchback: false,
    coupe: false,
    crossover: false,
    minivan: false,
    suv: false,
    wagon: false,
    truck: false,
  },

  chargerWifiFilter: {
    yes: false,
    no: false,
  },

  chargerTypeFilters: {
    mounted: false,
    portable: false,
  },

  chargerFormFactorFilters: {
    "6-20": false,
    "6-30": false,
    "10-30": false,
    "14-30": false,
    "6-50": false,
    "14-50": false,
    "14-60": false,
    Hardwired: false,
  },

  selectedEnergyCostIds: energyCostTypes
    .map((costType) => costType.id)
    .filter((id) => id !== HOME_ENERGY_COST_ID),
  vehicleIdForRateComparison: "",
  usedVehicleIdForRateComparison: "",
  chargingPatternId: states[0].chargingPatterns[0].id,
  chargerPaymentType: chargerPaymentOptions[0].id,
  chargingLocation: chargingLocations.find((l) => l.id === ATTACHED_GARAGE).id,
  electricPanelLocation: electricPanelLocations.find(
    (l) => l.id === BASEMENT_PANEL_LOCATION
  ).id,
  electricPanelLocationRoomType: electricPanelLocationRoomTypes[0].id,
  panelDistanceToChargerInFeet: 50,
  currentRateId: states[0].rates.find((rate) => rate.isDefault).id,
  currentMonthlyBill: 70,
  publicChargingPercentage: 0,
  publicChargingCostInCents: 20,
  selectedRateTypeIds: [BUNDLED_RATE_TYPE_ID],
  rateComparisonVehicleCondition: NEW_CONDITION_ID,
};

export default USER_PREF_PRESETS;
